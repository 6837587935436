import { Loading } from 'components';
import { navigate } from 'gatsby';
import { Distributor } from 'modules/distributors';
import { DistributorThunks } from 'modules/distributors/redux/thunks';
import { ApplicationState } from 'modules/redux-store';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CustomerInbox = lazy(
  () => import('modules/inbox/components/CustomerInbox'),
);
const DistributorInbox = lazy(
  () => import('modules/inbox/components/DistributorInbox'),
);

const Inbox: React.FC = () => {
  const { user } = useSelector((state: ApplicationState) => state.auth);
  const [allDistributors, setAllDistributors] = useState<Distributor[]>([]);

  useEffect(() => {
    if (!user) navigate('/prijava');
    else if (user?.isAnonymous) navigate('/');
  }, [user]);

  useEffect(() => {
    DistributorThunks.getAllDistributors().then(setAllDistributors);
  }, []);

  if (!allDistributors || !allDistributors.length)
    return <Loading isLoading={!allDistributors.length} />;

  if (
    (user?.role === 'customer' ||
      user?.role === 'admin' ||
      user?.role === 'sales') &&
    !user?.isAnonymous
  ) {
    return (
      <Suspense fallback={<Loading isLoading />}>
        <CustomerInbox userId={user.uid} distributors={allDistributors} />
      </Suspense>
    );
  } else if (user?.role === 'distributor' && user?.distributorId) {
    const distributors: Distributor[] = [];

    if (Array.isArray(user.distributorId)) {
      user.distributorId.map((distId) => {
        const dist = allDistributors.find(
          (dist: Distributor) => dist.id === distId,
        );

        if (dist) distributors.push(dist);
      });
    } else {
      const dist = allDistributors.find(
        (dist: Distributor) => dist.id === user.distributorId,
      );
      if (dist) distributors.push(dist);
    }
    return (
      <Suspense fallback={<Loading isLoading />}>
        <DistributorInbox distributors={distributors} />
      </Suspense>
    );
  } else {
    return <Loading isLoading fullPage />;
  }
};

export default Inbox;
